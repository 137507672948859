import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  ReferenceManyField,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  Pagination,
  Button,
  useShowController,
  SimpleFormIterator,
  ArrayInput
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { Card, CardContent } from "@material-ui/core";
import BulkActionSendSms from "../components/BulkActionSendSms";
import BulkActionSendEmail from "../components/BulkActionSendEmail";
import BulkActionButtonCampaignAccept from "../components/BulkActionButtonCampaignAccept";
import BulkActionButtonCampaignNoShipment from "./BulkActionButtonCampaignNoShipment";
import BulkActionButtonCampaignReject from "../components/BulkActionButtonCampaignReject";
import BulkActionButtonCampaignInitiate from "../components/BulkActionButtonCampaignInitiate";
import BulkActionButtonCampaignInvite from "../components/BulkActionButtonCampaignInvite";
import BulkActionButtonMonetize from "../components/BulkActionButtonMonetize";
import { AssignFlag } from "./AssignFlag";
import { AssignTag } from "./AssignTag";
import BulkActionButtonAssignToCampaign from "../components/BulkActionButtonAssignToCampaign";
import AsideFilters from "../components/AsideFilters";
import UserGrid from "../components/UserGrid";

export const CampaignList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="resource_name" label="Campaign" />
        <DateField source="date_created" label="Date Created" />
        <EditButton basePath="/campaign" />
        <ShowButton basePath="/campaign" />
      </Datagrid>
    </List>
  );
};

export const CampaignEdit = (props) => {
  return (
    <Edit title="Edit Campaign" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Campaign Name (internal)" />
        <TextInput source="title" label="Campaign Title" />
        <SelectInput
          source="type"
          label="Campaign Type"
          choices={[
            { id: "digital", name: "Digital" },
            { id: "regular", name: "Regular" },
            { id: "premium_digital", name: "Premium Digital" },
            { id: "premium_regular", name: "Premium Regular" },
          ]}
        />
        <SelectInput
          source="status"
          label="Campaign Status"
          choices={[
            { id: "draft", name: "Draft" },
            { id: "active", name: "Active" },
            { id: "past", name: "Past" },
            { id: "invite", name: "Invite" },
            { id: "invite_open", name: "Open Invite" },
          ]}
        />
        <DateInput
          disabled
          source="date_created"
          label="Date Created"
        ></DateInput>
        <TextInput source="brand_logo" label="Brand Logo" />
        <TextInput source="brand_name" label="Brand Name" />
        <TextInput source="product_image" label="Product Image" />
        <TextInput source="product_name" label="Product Name" />
        <TextInput source="SMS.invitation" label="SMS Text" />
        <TextInput source="review_site" label="Review Site" />
        <RichTextInput
          source="terms"
          label="Terms"
        />
        <RichTextInput
          source="description"
          label="Description: Shown in invite"
        />
        <RichTextInput
          source="instructions"
          label="Instructions: Shown in campaign list"
        />
      
        <TextInput source="existing_invite_email_subject" label="Existing - Invite Email Subject - Existing Members" />
        <RichTextInput
          source="existing_invite_email_body"
          label="Existing - Invite Email Body - Existing Members"
        />
        <TextInput source="new_invite_email_subject" label="New - Invite Email Subject - New Members" />
        <RichTextInput
          source="new_invite_email_body"
          label="New - Invite Email Body - New Members"
        />
          <ArrayInput source="varieties">
          <SimpleFormIterator>
             <TextInput source="product_id" label="Product ID"/>
            <TextInput source="product_name" label="Product Name"/>
            <TextInput source="product_description" label="Product Description"/>
            <TextInput source="review_link" label="Product Link"/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const CampaignCreate = (props) => {
  return (
    <Create title="Create Campaign" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Campaign" />
        <SelectInput
          source="status"
          label="Campaign Status"
          choices={[
            { id: "draft", name: "Draft" },
            { id: "active", name: "Active" },
            { id: "past", name: "Past" },
            { id: "invite", name: "Invite" },
            { id: "invite_open", name: "Open Invite" },
            // ... add more types as needed
          ]}
        />
      </SimpleForm>
    </Create>
  );
};



const CampaignUserPagination = () => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} />
);



// Step 3: Custom BulkActionButtons component
const CustomBulkActions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Add To Campaign</Button>
      <BulkActionButtonAssignToCampaign
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <Button onClick={() => setFlagModalOpen(true)}>Flag User</Button>
      <AssignFlag
        open={flagModalOpen}
        handleClose={() => setFlagModalOpen(false)}
      />
      <Button onClick={() => setTagModalOpen(true)}>Tag User</Button>
      <AssignTag
        open={tagModalOpen}
        handleClose={() => setTagModalOpen(false)}
      />
    </>
  );
};


export const CampaignShow = (props) => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const campaign_id = record ? record.id : null;
  const [smsText, setSmsText] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  useEffect(() => {
    if (record) {
      setSmsText(record.SMS?.invitation || "");
      setEmailSubject(record.EMAIL?.invitation?.subject || "");
      setEmailBody(record.EMAIL?.invitation?.body || "");
    }
  }, [record]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="resource_name" label="Campaign Name" />
        <TextField source="SMS.invitation" label="SMS Text" />
        <ReferenceManyField
          label="Users in this Campaign"
          reference="campaignuser"
          target="campaign_id"
          pagination={null}
        >
          <Card>
            <CardContent style={{ display: "flex", paddingTop: "50px" }}>
              <AsideFilters />
              <UserGrid
                {...props}
                bulkActionButtons={
                  <>
                    <BulkActionButtonCampaignAccept campaign_id={campaign_id} />
                    <BulkActionButtonCampaignNoShipment campaign_id={campaign_id} />
                    <BulkActionButtonCampaignReject campaign_id={campaign_id} />
                    <BulkActionButtonCampaignInvite campaign_id={campaign_id} />
                    <BulkActionButtonCampaignInitiate campaign_id={campaign_id} />
                    <BulkActionButtonMonetize campaign_id={campaign_id} />
                    <CustomBulkActions />
                    <BulkActionSendSms
                      resource_id={campaign_id}
                      initial_smsText={smsText}
                    />
                    <BulkActionSendEmail
                      resource_id={campaign_id}
                      initial_emailBody={emailBody}
                      initial_emailSubject={emailSubject}
                    />
                  </>
                }
              />
            </CardContent>
          </Card>
          <CampaignUserPagination />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};