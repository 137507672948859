// components/AsideFilters.js

import React from "react";
import {
  FilterList,
  FilterListItem,
  useGetList,
} from "react-admin";

const AsideFilters = () => {
  const { data: tags, isLoading } = useGetList("tag", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "resource_name", order: "ASC" },
    filter: {},
  });

  return (
    <div style={{ width: 200, margin: "1em" }}>
      <FilterList label="Campaign Status">
        <FilterListItem label="Invited" value={{ campaign_status: "invited" }} />
        <FilterListItem label="Applied" value={{ campaign_status: "applied" }} />
        <FilterListItem label="Accepted" value={{ campaign_status: "accepted" }} />
        <FilterListItem label="Rejected" value={{ campaign_status: "rejected" }} />
        <FilterListItem label="Initiated" value={{ campaign_status: "initiated" }} />
        <FilterListItem label="No Shipment" value={{ campaign_status: "no_shipment" }} />
      </FilterList>

      <FilterList label="Gender">
        <FilterListItem label="Female" value={{ gender: "Female" }} />
        <FilterListItem label="Male" value={{ gender: "Male" }} />
        <FilterListItem label="Other" value={{ gender: "Other" }} />
        <FilterListItem label="Decline" value={{ gender: "Decline" }} />
      </FilterList>

      <FilterList label="Min Age">
        <FilterListItem
          label="25"
          value={{ min_age: `${new Date().getFullYear() - 25}-00-00` }}
        />
        <FilterListItem
          label="30"
          value={{ min_age: `${new Date().getFullYear() - 30}-00-00` }}
        />
        <FilterListItem
          label="35"
          value={{ min_age: `${new Date().getFullYear() - 35}-00-00` }}
        />
        <FilterListItem
          label="40"
          value={{ min_age: `${new Date().getFullYear() - 40}-00-00` }}
        />
      </FilterList>

      <FilterList label="Max Age">
        <FilterListItem
          label="30"
          value={{ max_age: `${new Date().getFullYear() - 30}-00-00` }}
        />
        <FilterListItem
          label="35"
          value={{ max_age: `${new Date().getFullYear() - 35}-00-00` }}
        />
        <FilterListItem
          label="40"
          value={{ max_age: `${new Date().getFullYear() - 40}-00-00` }}
        />
        <FilterListItem
          label="45"
          value={{ max_age: `${new Date().getFullYear() - 45}-00-00` }}
        />
      </FilterList>

      <FilterList label="Min TikTok Followers">
        <FilterListItem label="1" value={{ min_follower_count: 1 }} />
        <FilterListItem label="100" value={{ min_follower_count: 100 }} />
        <FilterListItem label="500" value={{ min_follower_count: 500 }} />
        <FilterListItem label="1000" value={{ min_follower_count: 1000 }} />
      </FilterList>
      <FilterList label="Show Flagged">
        <FilterListItem label="Yes" value={{ flagged: true }} />
      </FilterList>

      <FilterList label="Tags">
        {!isLoading && tags
          ? tags.map((tag) => (
              <FilterListItem
                key={tag.id}
                label={tag.resource_name || "Unnamed Flag"} // Fallback for undefined names
                value={{ tag: `${tag.id}+${tag.resource_name || "unnamed_flag"}` }}
              />
            ))
          : <div>Loading...</div>}
      </FilterList>
    </div>
  );
};

export default AsideFilters;
