import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import {
  useNotify,
  useRefresh,
  useListContext,
  useDataProvider,
} from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

export const AssignTag = ({ open, handleClose }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  // Clear selectedTags when the dialog is opened
  useEffect(() => {
    if (open) {
      setSelectedTags([]);
    }
  }, [open]);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("tag", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setTags(data);
      })
      .catch((error) => {
        notify("Error loading tags: " + error.message, { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]);

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      // Send an array of tag IDs
      await API.post("secure-api", "api/admin/tagassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          resource_ids: selectedTags, // Now an array
        },
      });
      notify("Users updated successfully", { type: "info" });
      onUnselectItems();
      refresh();
      handleClose(); // close dialog on success
    } catch (error) {
      notify("Error: Users not updated", { type: "warning" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Assign Tag</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading tags...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="tag-select-label">Tags</InputLabel>
            <Select
              labelId="tag-select-label"
              id="tag-select"
              multiple // Enable multiple selection
              value={selectedTags}
              onChange={(e) => setSelectedTags(e.target.value)}
              renderValue={(selected) =>
                tags
                  .filter((tag) => selected.includes(tag.id))
                  .map((t) => t.resource_name)
                  .join(", ")
              }
            >
              {tags.map((tagItem) => (
                <MenuItem key={tagItem.id} value={tagItem.id}>
                  <Checkbox checked={selectedTags.indexOf(tagItem.id) > -1} />
                  <ListItemText primary={tagItem.resource_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};
