import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import {
  useNotify,
  useRefresh,
  useListContext,
  useDataProvider,
} from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

export const AssignFlag = ({ open, handleClose }) => {
  const [selectedFlags, setSelectedFlags] = useState([]); // multiple flags
  const [flags, setFlags] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  // Clear the selected flags each time the dialog is opened
  useEffect(() => {
    if (open) {
      setSelectedFlags([]);
    }
  }, [open]);

  // Load flags from the dataProvider
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("flag", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setFlags(data);
      })
      .catch((error) => {
        notify("Error loading flags: " + error.message, { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]);

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/flagassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          resource_ids: selectedFlags,
        },
      });
      notify("Users updated successfully", { type: "info" });
      onUnselectItems();
      refresh();
      handleClose();
    } catch (error) {
      notify("Error: Users not updated", { type: "warning" });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Assign Flag</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading flags...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="flag-select-label">Flags</InputLabel>
            <Select
              labelId="flag-select-label"
              id="flag-select"
              multiple
              value={selectedFlags}
              onChange={(e) => setSelectedFlags(e.target.value)}
              renderValue={(selected) =>
                flags
                  .filter((flag) => selected.includes(flag.id))
                  .map((f) => f.resource_name)
                  .join(", ")
              }
            >
              {flags.map((flagItem) => (
                <MenuItem key={flagItem.id} value={flagItem.id}>
                  <Checkbox checked={selectedFlags.indexOf(flagItem.id) > -1} />
                  <ListItemText primary={flagItem.resource_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};
