import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Button as MuiButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import ReactQuill from "react-quill"; 
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNotify, useListContext, useRefresh } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

const BulkActionSendEmail = ({
  resource_id,
  initial_emailBody,
  initial_emailSubject,
}) => {
  const [open, setOpen] = useState(false);
  
  // Email fields
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  // Send now vs later
  const [sendOption, setSendOption] = useState("now");
  const [scheduledDateTime, setScheduledDateTime] = useState(new Date());

  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleOpen = () => {
    setEmailSubject(initial_emailSubject || "");
    setEmailBody(initial_emailBody || "");
    setSendOption("now");
    setScheduledDateTime(new Date());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async () => {
    try {
      const header = await Header.getHeader();

      await API.post("secure-api", "api/process", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          action: "Email",
          email_subject: emailSubject,
          email_body: emailBody, // HTML from ReactQuill
          users: selectedIds,
          resource_id: resource_id,
          // Include scheduling info
          schedule_option: sendOption,
          schedule_time:
            sendOption === "later" ? scheduledDateTime.toISOString() : null,
        },
      });

      notify("Email request processed successfully", { type: "info" });
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify(`Error: Email not sent. ${error.message || error}`, { type: "warning" });
    }
  };

  return (
    <>
      <Button label="Send Email" onClick={handleOpen}>
        Send Email
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Send Email</DialogTitle>

        <DialogContent>
          <MuiTextField
            label="Email Subject"
            type="text"
            fullWidth
            value={emailSubject}
            onChange={(event) => setEmailSubject(event.target.value)}
            margin="normal"
          />
        </DialogContent>

        <DialogContent>
          <ReactQuill
            theme="snow"
            value={emailBody}
            onChange={(content) => setEmailBody(content)}
            style={{ minHeight: "150px" }}
          />
        </DialogContent>

        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={sendOption}
              onChange={(e) => setSendOption(e.target.value)}
            >
              <FormControlLabel
                value="now"
                control={<Radio color="primary" />}
                label="Send Now"
              />
              <FormControlLabel
                value="later"
                control={<Radio color="primary" />}
                label="Schedule for Later"
              />
            </RadioGroup>
          </FormControl>

          {sendOption === "later" && (
            <div style={{ marginTop: "16px" }}>
              <DatePicker
                selected={scheduledDateTime}
                onChange={(date) => setScheduledDateTime(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="Pp"
                timeCaption="Time"
                minDate={new Date()}
                placeholderText="Select date and time"
                className="react-datepicker__input-text"
                withPortal
                popperPlacement="bottom-start"
              />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          <MuiButton
            color="primary"
            variant="contained"
            onClick={handleSendEmail}
            disabled={sendOption === "later" && !scheduledDateTime}
          >
            {sendOption === "now" ? "Send Now" : "Schedule"}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkActionSendEmail;
