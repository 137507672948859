// components/BulkActionSendSms.js

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Button as MuiButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNotify, useListContext, useRefresh } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

const BulkActionSendSms = ({ resource_id, initial_smsText }) => {
  const [open, setOpen] = useState(false);
  const [smsText, setSmsText] = useState(initial_smsText || "");
  
  // Send Now vs Schedule for Later
  const [sendOption, setSendOption] = useState("now");
  const [scheduledDateTime, setScheduledDateTime] = useState(new Date());

  // Loading state
  const [loading, setLoading] = useState(false);

  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  // Function to open the modal dialog
  const handleOpen = () => {
    setSmsText(initial_smsText || "");
    setSendOption("now");
    setScheduledDateTime(new Date());
    setOpen(true);
  };

  // Function to close the modal dialog
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  // Function to send SMS
  const handleSend = async () => {
    // Basic validation
    if (sendOption === "later" && !scheduledDateTime) {
      notify("Please select a date and time for scheduling.", { type: "warning" });
      return;
    }

    if (!smsText.trim()) {
      notify("SMS message cannot be empty.", { type: "warning" });
      return;
    }

    setLoading(true);
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/process", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          action: "SMS",
          sms_text: smsText,
          users: selectedIds,
          resource_id: resource_id,
          // Include scheduling info
          schedule_option: sendOption,
          schedule_time:
            sendOption === "later" ? scheduledDateTime.toISOString() : null,
        },
      });
      notify("SMS request processed successfully", { type: "info" });
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify(`Error: SMS not sent. ${error.message || error}`, { type: "warning" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button label="Send SMS" onClick={handleOpen}>
        Send SMS
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <MuiTextField
            autoFocus
            margin="normal"
            id="smsText"
            label="SMS Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={smsText}
            onChange={(event) => setSmsText(event.target.value)}
            variant="outlined"
          />
        </DialogContent>

        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={sendOption}
              onChange={(e) => setSendOption(e.target.value)}
            >
              <FormControlLabel
                value="now"
                control={<Radio color="primary" />}
                label="Send Now"
              />
              <FormControlLabel
                value="later"
                control={<Radio color="primary" />}
                label="Schedule for Later"
              />
            </RadioGroup>
          </FormControl>

          {sendOption === "later" && (
            <div style={{ marginTop: "16px" }}>
              <DatePicker
                selected={scheduledDateTime}
                onChange={(date) => setScheduledDateTime(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="Pp"
                timeCaption="Time"
                minDate={new Date()}
                placeholderText="Select date and time"
                className="react-datepicker__input-text"
                withPortal
                popperPlacement="bottom-start"
              />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <MuiButton onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </MuiButton>
          <MuiButton
            color="primary"
            variant="contained"
            onClick={handleSend}
            disabled={
              loading ||
              (sendOption === "later" && !scheduledDateTime) ||
              !smsText.trim()
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : sendOption === "now" ? (
              "Send Now"
            ) : (
              "Schedule"
            )}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkActionSendSms;
